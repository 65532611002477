.fishing-tool {
    text-align: center;
  }

  .carousel .control-dots .dot {
    bottom: -15px; /* Adjust this value to move the dots further down */
    position: relative;
  }

  .modal {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    align-content: center;
    margin-left: 20%;
    height: 80%;
    width: 50%;
    transform: scale();
    border-radius: 10px;
    z-index: 9999; /* Ensure the modal is on top of other elements */
  }

/* FishingTool.css */

.nighttime {
  background-color: rgba(0, 0, 0, 0.6); /* Translucent black background */
  color: white; /* White text to contrast with the black background */
  padding: 10px;
  border-radius: 8px; /* Optional: Rounded corners */
}

.bold-wind {
  font-weight: bold; /* Make the text bold */
}

.weather-icon {
  border-radius: 10px;
}

.weather-grid {
  display:flex;
  overflow-x: scroll;
  padding: 10px;
  gap: 15px; /* Space between items */
  scroll-snap-type: x mandatory;
}



.weather-item {
  flex: 0 0 auto; /* Prevent items from shrinking and allow them to maintain their width */
  width: 150px; /* Adjust the width of each item as needed */
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  scroll-snap-align: start;
}

/* Ensure the weather row is scrollable */
.weather-row::-webkit-scrollbar {
  height: 8px;
}

.weather-row::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.weather-row::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

/* FishingTool.css */
.hourly-weather-grid {
  display: flex;
  overflow-x: scroll;
  padding: 10px;
  gap: 15px; /* Space between items */
  scroll-snap-type: x mandatory;
}

.hourly-weather-item {
  flex: 0 0 auto; /* Prevent items from shrinking and allow them to maintain their width */
  width: 150px; /* Adjust the width of each item as needed */
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  scroll-snap-align: start;
}

/* Ensure the hourly weather row is scrollable */
.hourly-weather-row::-webkit-scrollbar {
  height: 8px;
}

.hourly-weather-row::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.hourly-weather-row::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
