.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal {
  z-index: 9997 !important;
  position: fixed,center, absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  margin: 10%;
  padding: 10px;
  border: 1px solid #888;
  width: 100%;
  background-color: grey;
  display: table-caption;
  
}

.close {
  color: red;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

/* Add this CSS to make the navbar sticky */
.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.lightbulb {
  position: sticky;
  top: 0;
  z-index: 1001;
}

/* Optional: Add styles for when the navbar is scrolled */
#createNewContact {
  padding-top: 80px; /* Adjust this value based on the height of your navbar */
}

#skills {
  padding-top: 80px; /* Adjust this value based on the height of your navbar */
}

#projects {
  padding-top: 80px; /* Adjust this value based on the height of your navbar */
}

#resume {
  padding-top: 80px; /* Adjust this value based on the height of your navbar */
}
.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.7);
}

.lightbulb {
  cursor: pointer;
}


/*Dark mode css*/

.dark {
  background-color: #333;
  color: #fff;
}
.light {
  background-color: #fff;
  color: #333;
}

/*select the admin color*/
.dropdown {
  color: #29a329 !important;
}

.dropdown .navbar-link .dropdown-toggle .nav-link {
  color: #29a329 !important;
}

.navbar-link {
  color: #29a329 !important;
}

.logo {
  height: 60%;
  width: 60%;
}