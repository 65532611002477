.burn-deck-practice-tool {
  text-align: center;
}

.game-board {
  display: grid;
  align-items: center;
  margin: 20px 0;
}

.mainMonsterZone {
  margin: 10px;
  padding: 10px;
  border: 1px solid #000;
  width: 250px;
  text-align: center;
  text-shadow: rgb(84, 84, 84) 0px 0px 5px;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
  background-color: orange;
}

.spellAndTrapZone {
  margin: 10px;
  padding: 10px;
  border: 1px solid #000;
  width: 250px;
  text-align: center;
  text-shadow: rgb(84, 84, 84) 0px 0px 5px;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  background-color: rgba(0, 255, 140, 0.8);
}

.handZone {
  margin: 10px;
  padding: 10px;
  border: 1px solid #000;
  width: 250px;
  text-align: center;
  text-shadow: rgb(84, 84, 84) 0px 0px 5px;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  background-color: rgba(191, 187, 68, 1);
}

.extraMonsterZone {
  margin: 10px;
  padding: 10px;
  border: 1px solid #000;
  width: 100px;
  text-align: center;
  text-shadow: rgb(84, 84, 84) 0px 0px 5px;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 5;
  background-color: blue;
}

.fieldSpellZone {
  margin: 10px;
  padding: 10px;
  border: 1px solid #000;
  width: 100px;
  text-align: center;
  text-shadow: rgb(84, 84, 84) 0px 0px 5px;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
  background-color: rgb(109, 162, 30);
}

.graveyardZone {
  margin: 10px;
  padding: 10px;
  border: 1px solid #000;
  width: 100px;
  text-align: center;
  text-shadow: rgb(84, 84, 84) 0px 0px 5px;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 4;
  background-color: gray;
}

.extraDeckZone {
  margin: 10px;
  padding: 10px;
  border: 1px solid #000;
  width: 100px;
  text-align: center;
  text-shadow: rgb(84, 84, 84) 0px 0px 5px;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
  background-color: lightgray;
}

.banishedZone {
  margin: 10px;
  padding: 10px;
  border: 1px solid #000;
  width: 100px;
  text-align: center;
  text-shadow: rgb(84, 84, 84) 0px 0px 5px;
  grid-column-start: 0;
  grid-column-end: 1;
  grid-row-start: 3;
  grid-row-end: 4;
}

.deckZone {
  margin: 10px;
  padding: 10px;
  border: 1px solid #000;
  width: 100px;
  text-align: center;
  text-shadow: rgb(84, 84, 84) 0px 0px 5px;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  background-color: rgb(152, 103, 44);
}

.chainLinkZone {
  margin: 10px;
  padding: 10px;
  border: 1px solid #000;
  width: 100px;
  text-align: center;
  text-shadow: rgb(84, 84, 84) 0px 0px 5px;
  grid-column-start: 0;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 2;
  background-color: red;
}

button {
  margin: 20px;
  padding: 10px 20px;
  font-size: 16px;
}

.modal {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  align-content: center;
  margin-left: 20%;
  height: 80%;
  width: 50%;
  transform: scale();
  border-radius: 10px;
  z-index: 9999; /* Ensure the modal is on top of other elements */
}

.timer {
  margin-top: 20px;
  font-size: 18px;
}

.ruleClose {
  color: red;
  float:inline-start;
  font-size: 28px;
  font-weight: bold;
}

.modal-content {
  margin: 10%;
  padding: 10px;
  border: 1px solid #888;
  width: 100%;
  background-color: grey;
  display: table-caption;
  
}